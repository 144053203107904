.VideoPlayer {
  position: relative;
  flex-shrink: 1;

  margin: 20px 0 5px;

  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.VideoPlayer video {
  position: relative;
  z-index: 10;

  /* leave room for storytext and replay button and margin */
  max-height: calc(100vh - 256px - 79px - 40px);
  /* ARRRRG flexbox doesn't respect height: 100% for some reason */
	/*height:100%;*/
  border: solid 5px #000;
  box-shadow: rgba(0, 0, 0, 0.39) 5px 5px 5px;

  background: rgba(255, 255, 255, 0.7) center center no-repeat;
}

.VideoPlayer.shown {
  opacity: 1;
}


.VideoPlayer .lds-default {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  z-index: 1;
}


/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 

  .VideoPlayer {
    margin-top: 0px;
  }

  .VideoPlayer video {
    /* leave room for storytext and replay button and margin */
    max-height: calc(100vh - 30vh - 79px - 4px);
  }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}