.App {
  text-align: center;

  /** TODO: not the best way to hide the scrollbar when ribbon is clicked, but works for now **/
  position: relative; 
  height:100vh;
  overflow: hidden;
}

/*** PANES ***/

.ribbon {
  width: 100%;
  height: 91px;
  margin-top: -15px;
  background: no-repeat top right;
  position: absolute;
  z-index:500;
}

.ribbonHandle {
  width: 83px;
  height: 76px;
  /* margin-top:15px; */
  background: no-repeat;
  background-size: contain;
  position: absolute;
  right: 22px;
}

.pane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
}

.paneUnder {
    
}

.paneOver {
    transform: translateY(0px);
}


.App-pagination {
  position: fixed;
  width: 50px;
  height: 100vh;
  top: 0;

  font-size:0;
  text-indent:-9999px;
  overflow: hidden;
  z-index: 400;

  background: url('/images/ic_keyboard_arrow_right_white.svg') no-repeat center center;

  transition: all 0.2s ease-in-out;
}

.App-pagination:hover {
  transform: scale(1.3);
}

.App-pagination__prev {
  left:0;
  background-image: url('/images/ic_keyboard_arrow_left_white.svg');
}

.App-pagination__next {
  right:0;
}

