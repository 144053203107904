.HomeLinks {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
}

.Home-Link {
	display:block;
	width: 234px;
	max-width: 30%;
	height: 246px;
    max-height: 30vh;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;

    font-size: 0px;
    text-indent: -99999px;
    overflow: hidden;

    margin: 0 25px;
}

.Home-Link,
.Home-Link:hover,
.Home-Link:visited,
.Home-Link:active {

}

.Home-Link-Floating {	
	width: 88px;
	height: 90px;
	margin: 20px;

	position: absolute;
	bottom: 10px;
    right: 10px;
}


.showOnlyHome.Home-Link,
.showOnlyHome.Home-Link:hover,
.showOnlyHome.Home-Link:visited,
.showOnlyHome.Home-Link:active {
	width: calc(234px / 2);
	height: calc(246px / 2);
}

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.HomeLinks .break {
    flex-basis: 100%;
    height: 0;
}

.HomeLinks.linebreaks_1,
.HomeLinks.linebreaks_2 {
    flex-wrap: wrap;
} 

/* 
 This changes the height of each link to be half of the hight of the .HomeLinks container.
 You'll need to manually change the height and margin-top of .HomeLinks to fit them in the screen correctly.

 linebreaks_1: is for one linebreak (two rows)
 linebreaks_2: is for two linebreaks (three rows)
*/
.HomeLinks.linebreaks_1 .Home-Link {
    height: 50%;
}

.HomeLinks.linebreaks_2 .Home-Link {
    height: 30%;
}


/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 


	body div .Home-Link-Floating,
	body div .showOnlyHome.Home-Link-Floating
	{	
		margin: 5px;

		bottom: 5px;
		right: 5px;
		left: auto;
		
		width: calc(234px * 0.4);
		height: calc(246px * 0.4);
		max-height: 25vh;
	}
	
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}