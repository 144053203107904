.downloadReadButton > * {
    display: inline-block;
    background: white;
    color: #10B4C9;
    border: 2px solid #10B4C9;
    border-radius: 21px;
    padding: 8px 13px;
    min-width: 105px;
    min-height: 40px;

    box-sizing: border-box;

    font-family: 'Nunito';
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;

    text-decoration: none;
}

.downloadReadButton button {
    position: relative;
    overflow: hidden;
    padding: 0;
}

.downloadReadButton button > * {
    position: absolute;
    left: 0;
    top: 0;
}

.downloadReadButton button svg {
    margin: -2px 0 0 -2px;
}

.downloadReadButton button span {
    font-size: 0;
}

.downloadReadButton.read > * {
    background-color: #10B4C9;
    color: #fff;
    text-transform:uppercase;
    font-weight: 700;

    outline: solid 4px #fff;
}

div .downloadReadButton.error > * {
    background-color: #c91010;
    color: #fff;
    text-transform:uppercase;
    font-weight: 700;

    outline: solid 4px #fff;
    border: none;
}