.Page {
    height:100vh;
    width: 100vw;
}

.pageBackground {
  height: 100vh;
  width: 300vw;
}

.pageBackground .backgroundSlide {
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-repeat: no-repeat;
  background-size: cover;
}

  .pageBackground > :nth-child(1) {
    margin-left: -100vw;
  }

  .pageBackground > :nth-child(3) {
    margin-left: 100vw;
  }

.pageForeground {
    position: absolute;
    top: 0;
    left: 0;

    height:100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    padding-top: 20px;
    box-sizing: border-box;
}

.backgroundSlide.lastPage {  
  /** Make sure "The End" shows up on 16:9 tablets **/
  background-position-y: center;
}

.replayButton {
  width: 80px;
  height: 79px;
  padding:0;
  flex-shrink: 0;

  font-size:0;
  text-indent:-9999px;
  overflow: hidden;

  border:none;
  background: no-repeat center center;
  background-size: contain;

  position: absolute;
  bottom: calc(300px - 79px);

  z-index: 20;
}

/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 
  .replayButton {
    width: 50px;
    height: 50px;
    bottom: calc(30vh - 5px * 2);
  }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}