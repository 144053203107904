.book {
    position: relative;
    padding: 44px 32px 38px;

    box-sizing: border-box;

    flex-basis: 32%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 8px;
    /* width: 100%; */
    height: 210px;
    background-color: #ffffff;
    /* border-radius: 10px; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
}

@media screen and (max-width: 900px) {
    .book {
        flex-basis: 49%;
    }

}

.book img {
    width: 128px;
    margin: 26px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}

.book .bookInfo {
    height: 128px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.book .bookInfo h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin: 0;
}

.book .bookInfo .age {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 6px 0 18px;
}

.book > .favoriteButton {
    visibility: hidden;
    position: absolute;
    right: 20px;
    top: 20px;
}

.book > .favoriteButton.isFavorite {
    visibility: visible;
}

/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 
    .book {
        padding: 14px;
        height: auto;
    }

    .book img {
        width: 95px;
    }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}

/** old-school transitions */
.book .bookPreview,
.book .bookPreviewOverlay {
    transition: all 0.25s ease-in-out;
}

.book.hiding .bookPreview {
    transform: scale(0, 0); 
    opacity: 0;   
}

.book.showing .bookPreview {
    transform: scale(1, 1);    
    opacity: 1;
}

.book.hiding .bookPreviewOverlay {
    opacity: 0;   
}

.book.showing .bookPreviewOverlay {
    opacity: 1;
}

/* view transitions! */
.book.hidden:hover {
    view-transition-name: book-container;
}

.book.shown .bookPreview {
    view-transition-name: book-container;
}

.book.hidden:hover > img {
    view-transition-name: book-image;
}

.book.shown .bookPreviewHeader img {
    view-transition-name: book-image;
}

/* ::view-transition-old(book-container),
::view-transition-new(book-container) {
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
} */


