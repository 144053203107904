.PageList-Container {
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 20px 0;
  margin: 0;
}

.PageList {
  width: max-content;
}

.PageList-thumbnail {
  display: inline-block;
	margin:1em;
	white-space: nowrap;
}

.PageList-thumbnail img {
    box-shadow: rgba(17, 17, 17, 0.4) 4px 4px 4px;
    width:23vw;
}

/* increased drop shadow for the current page link */
.PageList-thumbnail:active img,
.PageList-thumbnail.current img {
  box-shadow: rgba(17, 17, 17, 0.8) 4px 4px 8px;
}


/** phone sizes **/



@media screen and (max-height: 390px) {
  .PageList-Container {
    padding: 0;
  }

  .PageList-thumbnail img {
    height: 20vh;
    width: auto;
  }
}

/* Small phones (320px - 480px) */
@media screen and (min-height: 391px) and (max-height: 450px) {
  .PageList-thumbnail img {
    max-height: 20vh;
    width: auto;
  }

  .PageList-Container {
    padding: 0;
  }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}