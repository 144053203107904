.Watch {
  height: 100vh;
  background: no-repeat;
  background-size: cover;

  padding-top: 0px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Watch .Home-Link {
  position: absolute;
  right: 20px;
  bottom:20px;

  width: calc(234px * 0.6);
  height: calc(246px * 0.6);
  max-height: 25vh;
}

.Watch .VideoPlayer video {
  max-height: calc(100vh - min(246px * 0.6, 25vh) - 40px - 15px);
  max-width: calc(100vw - 26px);
}


/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 
	body div .Watch .Home-Link-Floating {	
    right: 10px;
    bottom: 10px;

    width: calc(234px * 0.4);
    height: calc(246px * 0.4);
    max-height: 25vh;
	}

  .Watch {
    align-items: start; 
  }

  .Watch .VideoPlayer {
    padding: 8px;
    flex-shrink: unset;
    box-sizing: border-box;
  }
  
  .Watch .VideoPlayer video {
    max-height: calc(100vh - 8px * 2);
    max-width: calc(100vw - 234px * 0.4 - 8px * 2);
    box-sizing: border-box;
  }  
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}