header h1 {
    display: flex;
    align-content: center;
    
    height: 224px;
    margin: 5px 5% 5px;

    /* background-color: rgb(207, 248, 235); */

    background: url(/images/bookshelf/title.png) no-repeat center center;
    background-size: 100%;

    font-size: 0;
    text-indent: -9999px;
}