.bookPreviewOverlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(218, 228, 242, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.bookPreview {
    position: relative;
    margin: 86px;
    max-width: 900px;
    max-height: 584px;

    /* padding: 64px 112px; */

    display: flex;
    flex-direction: column;

    background-color: rgba(255, 255, 255);
    border: 2px black solid;
}

.bookPreview .close,
.bookPreview .favoriteButton {
    position: absolute;
    top: 64px;

    width: 44px;
    height: 44px;
   
    background-color: transparent;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;

    text-indent: -9999px;
    font-size: 0;
}

.bookPreview .close {
    background-image: url(/images/bookshelf/ic-close.svg);
    left: 64px;
}

.bookPreview .favoriteButton {
    right: 64px;
}

.bookPreview .bookPreviewHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /* margin: 64px 30px 30px; */
}

.bookPreview .bookPreviewHeader img {
    width: 215px;
    border-radius: 44px;
    margin: 0 60px 0 36px;
}

.bookPreview .bookPreviewHeader h2 {
    font-size: 42px;
    line-height: 40px;
    margin: 16px 0;
    font-weight: 800;
}

.bookPreview .bookPreviewHeader p {
    font-size: 22px;
    line-height: 32px;
    margin: 0;
}

.bookPreview .bookPreviewContent {
    margin: 64px 112px 0;
    overflow: scroll;
}

.bookPreview .bookPreviewBody {
    margin: 44px 0;
    padding: 8px;
    
    background: rgba(140, 211, 234, 0.2);
    border-radius: 20px;
}

.bookPreview .bookPreviewBody p {
    margin: 8px;
}

.bookPreview .bottomBar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 64px;

    /* height: 82px; */
    /* margin-top: 25px; half of button height */
    /* background-color: black; */
}

.deletable .bookPreview .bottomBar > * {
    z-index: 3;
}

.deletable .bookPreview .bottomBar::before {
    content: " ";
    
    position: absolute;
    left: 7px;
    right: 7px;
    top: 27px;
    bottom: 7px;

    background-color: #10B4C9;

    z-index: 1;
}

.bookPreview .bottomBar .deleteButton {
    position: absolute;
    left: 38px;
    bottom: 15px;

    width: 44px;
    height: 44px;
    
    background: #10B4C9 url(/images/bookshelf/ic_delete.svg) no-repeat center center;
    background-size: 33px 35px;
    border: none;

    text-indent: -9999px;
    font-size: 0;

    display: none;
}

.deletable .bookPreview .bottomBar .deleteButton {
    display: block;
}

.error {
    padding: 0;
    color:rgb(163, 0, 0)
}

.deletable .error {
    color: #eee;
}


/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 

  .bookPreview {
    margin: 6px;
    max-width: 95vw;
    max-height: 95vh;
    box-sizing: border-box;
  }

    .bookPreview .close,
    .bookPreview .favoriteButton {
        top: 22px;
    }

    .bookPreview .close {
        left: 22px;
    }
    .bookPreview .favoriteButton {
        right: 22px;
    }

    .bookPreview .bookPreviewHeader img {
        width: 105px;
        border-radius: 20px;
        margin: 0 30px 0 14px;
    }

    .bookPreview .bookPreviewHeader h2 {
        font-size: 22px;
        line-height: 20px;
        margin: 8px 0;
        font-weight: 800;
    }

    .bookPreview .bookPreviewHeader p {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
    }

    .bookPreview .bookPreviewContent {
        margin: 8px 8px 0;
        overflow: scroll;
    }

    .bookPreview .bookPreviewBody {
        margin: 22px 64px;
        padding: 8px;
        
        background: rgba(140, 211, 234, 0.2);
        border-radius: 20px;
    }

    .bookPreview .bottomBar {
        padding: 8px;
    }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}
