.Shaker {
  position: absolute;
  height:100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  background-repeat: no-repeat;
  background-size: cover;
}

.Shaker.shown {
  display: block;
  opacity: 1.0;
}

.Shaker.hiding {
  display: block;
  opacity: 0.0;
}

.Shaker.hidden {
  display: none;
  opacity: 0.0;
}