.Glossary {
  height: 100vh;
  background: no-repeat;
  background-size: cover;

  font-family: Georgia,sans-serif;
}


.Glossary-panel {
  float: left;
  box-shadow: rgba(0,0,0,0.3) 4px 4px 4px;
  height: 100vh;
  min-width: 30vw;
  overflow-y: scroll;
  overflow-x: auto;
  margin:0;
  padding:0;
  background: rgba(245, 245, 245, 0.22);
}

.Glossary-wordlist {
  list-style: none;
  list-style-type: none;
  margin:0;
  padding: 20px 0;
}

.Glossary-wordlist li {
    border-bottom: 1px #999 solid;
}

.Glossary-wordlist li a,
.Glossary-wordlist li a:hover,
.Glossary-wordlist li a:visited,
.Glossary-wordlist li a:active,
.Glossary-wordlist li a:link,
.Glossary-definition .Glossary-word {
    display: block;
    padding: 20px 20px;
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    text-shadow: rgba(0,0,0,0.3) 2px 2px 2px;
}

.Glossary-definition {
  height:100vh;
  padding-top: 50px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Glossary-definition .Glossary-word {
  font-size:3em;
}
