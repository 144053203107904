.Info {
  height: 100vh;
  background: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Info header {
  height: 140px;
  width:100%;
  background: repeat-x;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.Info-content {
  width: 100%;
  height: calc(100vh - 140px);
  /*padding: 20px calc(234px + 20px);*/

  box-sizing: border-box;
}

.Info-content iframe {
  width: 100%;
  height: 100%;

  border: none;
}

.Info-Link,
.Info-Link:hover,
.Info-Link:visited,
.Info-Link:active {
  display:block;
  background-position-x: 0px;
  background-position-y: 0px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
}

.Info-Link img {
  height: 63px;
}
