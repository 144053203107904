.snackbar {
    position: absolute;
    bottom: 1em;
    width: 50em;
    max-width: 90vw;
    margin: auto;
    padding: 1em;
    box-sizing: border-box;

    background: #9c0000;
    border-radius: 10px;
    color: white;
}