.StoryText {
  width: 1024px;
  max-width: 100vw;
  height: 256px;
  background: transparent no-repeat;
  background-size: cover;
  padding: 50px 40px;
  box-sizing: border-box;

  position: absolute;
  bottom: 20px;
  z-index: 10;
}

.StoryText iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/** TODO: inject these into storybook.css **/
.touchable.highlighted {
  text-decoration: underline;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.15), 0 1px 0 rgba(219, 208, 83, 0.98), 0 0 4px rgba(219, 221, 95, 0.83), 0 0 6px rgba(211, 219, 90, 0.68);
}


/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 
 
  .StoryText {
    height: 30vh;
    padding: 5px 4px;
    bottom: 0px;
  }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}