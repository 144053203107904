.bookshelf {
    padding: 24px;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    background-color: #DAE4F2;
}

.bookshelfContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
}