.NavigationPane {
  height: 100vh;

  background: no-repeat;
  background-size: cover;
}


.NavigationPane .HomeLinks {
  height: auto;
  bottom: auto;
  margin-top: 29px;
  position: relative;
}

/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 

  .NavigationPane .HomeLinks {
    margin-top: 10px;
  }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}