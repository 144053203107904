.Home, .HomeBackground {
	height:100vh;
	width: 100vw;
	position: absolute;
	
	transition: all 0.3s ease-in-out;
}

.HomeBackground {
	background: no-repeat center center;
	background-size: cover;
	
	transition-delay: 0.4s; /* delay must match VideoPlayer.css */
}

.Home.hidden .HomeBackground {
	opacity: 0;
}

.Home .HomeLinks {
    position: absolute;
		width: 100vw;
		height: 100vh;
    padding-top: 100px;
}

.HomeLinks {
	justify-content: space-evenly;
	align-items: center;

	box-sizing: border-box;
    /* padding: 0 160px; */
    padding: 0 60px;
}

body div .Home-Link_info {
	left: 10px;
}

.Home .VideoPlayer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 13433;

	background: white;
}

.Home .VideoPlayer video {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;

	border: none;
	box-shadow: none;
	margin: 0;
}

.Home .backToBookshelf {
	position: absolute;
	top: 25px;
	left: 25px;

	display:block;
	width: 185px;
	height: auto;
	aspect-ratio: 185 / 157;
    background-position-x: 0px;
    background-position-y: 0px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;

    font-size: 0px;
    text-indent: -99999px;
    overflow: hidden;
}

/** phone sizes **/

/* Small phones (320px - 480px) */
@media screen and (max-height: 450px) { 

  .Home .backToBookshelf {
	top: 5px;
	left: 5px;
	width: 80px;
  }

  .Home .HomeLinks {
    padding-top: 60px;
  }

  body div .Home-Link_info {
	left: 10px;
	right: auto;
  }
}

/* Medium phones (481px - 767px) */
@media screen and (min-height: 451px) and (max-height: 767px) {

}

/* Large phones (768px - 1023px) */
@media screen and (min-height: 768px) and (max-height: 1023px) {
  
}