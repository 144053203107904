.TutorialOverlay {
  position: absolute;
  height:100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10000;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  background: rgba(0, 0, 0, 0.7);
}

.TutorialOverlay.shown {
  display: block;
  opacity: 1.0;
}

.TutorialOverlay.hiding {
  display: block;
  opacity: 0.0;
}

.TutorialOverlay.hidden {
  display: none;
  opacity: 0.0;
}

.TutorialOverlay img {
  padding: 15px;
  position: absolute;
}

.TutorialOverlay .close {
  top: 0;
  left: 0;
}
.TutorialOverlay .dont-show {
  bottom: 0;
  left: 0;
}
.TutorialOverlay .pull-down {
  top: 0;
  right: 20px;
}
.TutorialOverlay .swipe {
  bottom: 0;
  right: 0;
}
.TutorialOverlay .tap-video {
  bottom: calc(300px - 79px + 10px);
  right: 50%;
  margin-right: -20px;
}
.TutorialOverlay .highlighted-words {
  bottom: 200px;
  right: 50px;
}

/* for other languages: show the full overlay from the content folder */
.TutorialOverlay .fullOverlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
}
.TutorialOverlay button.dont-show {
  position: absolute;
  width: 300px;
  height: 80px;
  opacity: 0;
}

@media (max-width: 700px) {

  .TutorialOverlay .highlighted-words {
    right: 20px;
  }

}

@media (max-height: 610px) {

  .TutorialOverlay img {
    transform: scale(0.6);
    transform-origin: 0 center;
    padding: 10px;
  }

  .TutorialOverlay .close {
    top: 0;
    left: 0;
    transform-origin: 0 top;
  }
  .TutorialOverlay .dont-show {
    bottom: 0;
    left: 0;
    transform-origin: 0 bottom;
  }
  .TutorialOverlay .pull-down {
    top: 0;
    right: 60px;
    transform-origin: right top;
  }
  .TutorialOverlay .swipe {
    bottom: 0;
    right: 0;
    transform-origin: right bottom;
  }
  .TutorialOverlay .tap-video {
    right: 50%;
    margin-right: 10px;
    transform-origin: right bottom;
  }
  .TutorialOverlay .highlighted-words {
    transform-origin: right bottom;
  }
}
