.favoriteButton {
    width: 16px;
    height: 16px;
    background: url(/images/bookshelf/heart-outline.svg) no-repeat center center;
    background-size: contain;
    border: none;

    text-indent: -9999px;
    font-size: 0;
}

.favoriteButton.isFavorite {
    background-image: url(/images/bookshelf/heart-filled.svg);
}