body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
    font-family: 'Nunito', 'Roboto', sans-serif;
}

/** brutal nuke-all fix for the invisible iframe blocking all clicks in dev mode
 See https://github.com/facebook/create-react-app/issues/11773
*/
body > iframe {
    pointer-events: none;
}

/** scrollbars **/
body {
    scrollbar-face-color: rgba(255, 255, 255, 0.2);
    scrollbar-shadow-color: rgba(255, 255, 255, 0.05);
    scrollbar-highlight-color: rgba(255, 255, 255, 0.05);
    scrollbar-3dlight-color: rgba(255, 255, 255, 0.05);
    scrollbar-darkshadow-color: rgba(255, 255, 255, 0.05);
    scrollbar-track-color: rgba(255, 255, 255, 0.05);
    scrollbar-arrow-color: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar {
    background: transparent;
}
 
 ::-webkit-scrollbar-thumb,
::-webkit-scrollbar-corner,
::-webkit-resizer,
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);

    /*box-shadow: rgba(0, 0, 0, 0.5) 0 0 5px;*/
}


@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-VariableFont_wght.ttf') format('truetype');
  }